import React, { useState } from "react";
import { generateLottoNumbers } from "../utils/lotto";

function Lotto() {
  const [numbers, setNumbers] = useState([]);

  const handleGenerate = () => {
    const newNumbers = generateLottoNumbers();
    setNumbers(newNumbers);
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.title}>로또 번호 추천기</h1>
      <button onClick={handleGenerate} style={styles.button}>
        번호 생성하기
      </button>
      {numbers.length > 0 && (
        <div style={styles.results}>
          <h2>추천 번호:</h2>
          <ul style={styles.numberList}>
            {numbers.map((num) => (
              <li key={num} style={styles.number}>{num}</li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}

// 세련된 스타일을 적용합니다.
const styles = {
  container: {
    textAlign: 'center',
    marginTop: '50px',
    fontFamily: 'Arial, sans-serif',
    backgroundColor: '#f4f4f4',
    padding: '20px',
    borderRadius: '10px',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
  },
  title: {
    color: '#333',
    fontSize: '28px',
    marginBottom: '20px',
  },
  button: {
    padding: '12px 24px',
    fontSize: '18px',
    color: '#fff',
    backgroundColor: '#555',  // 중립적인 회색
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
  },
  buttonHover: {
    backgroundColor: '#333',  // 호버 시 더 어두운 회색
  },
  results: {
    marginTop: '20px',
  },
  numberList: {
    listStyleType: 'none',
    padding: 0,
  },
  number: {
    fontSize: '24px',
    display: 'inline-block',
    margin: '10px',
    padding: '15px',
    borderRadius: '50%',
    backgroundColor: '#e0e0e0',
    color: '#333',
  },
};

export default Lotto;
