import React from "react";
import { Link } from 'react-router-dom';

function LottoPage({ posts = [] }) {  // posts가 undefined일 경우 빈 배열을 기본값으로 설정
  return (
    <div style={styles.container}>
      <h1 style={styles.title}>로또 게시판</h1>
      <Link to="/new">
        <button style={styles.button}>글쓰기</button>
      </Link>
      <table style={styles.table}>
        <thead>
          <tr>
            <th>No</th>
            <th>제목</th>
            <th>닉네임</th>
            <th>등록일</th>
            <th>조회</th>
            <th>추천</th>
          </tr>
        </thead>
        <tbody>
          {posts.map((post, index) => (
            <tr key={post.id}>
              <td>{post.id}</td>
              <td>
                <Link to={`/post/${post.id}`} style={styles.link}>
                  {post.title}
                </Link>
              </td>
              <td>{post.nickname}</td>
              <td>{post.date}</td>
              <td>{post.views}</td>
              <td>{post.likes}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

const styles = {
  container: {
    textAlign: "center",
    padding: "20px",
    maxWidth: "1000px",
    margin: "0 auto",
  },
  title: {
    fontSize: "2rem",
    marginBottom: "20px",
    color: "#333",
  },
  button: {
    padding: "10px 20px",
    backgroundColor: "#28a745",
    color: "white",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    fontSize: "16px",
    marginBottom: "20px",
    transition: "background-color 0.3s ease",
  },
  table: {
    width: "100%",
    borderCollapse: "collapse",
  },
  link: {
    textDecoration: "none",
    color: "#007bff",
  },
};

export default LottoPage;
