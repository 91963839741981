// src/components/GoogleAd.js
import React, { useEffect } from 'react';

function GoogleAd() {
  useEffect(() => {
    (window.adsbygoogle = window.adsbygoogle || []).push({});
  }, []);

  return (
    <div className="ad-container">
      <ins className="adsbygoogle"
           style={{ display: 'block' }}
           data-ad-client="YOUR_AD_CLIENT"
           data-ad-slot="YOUR_AD_SLOT"
           data-ad-format="auto"></ins>
    </div>
  );
}

export default GoogleAd;
