// src/App.js
import React from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import Community from './components/Community';
import GoogleAd from './components/GoogleAd';
import Lotto from './components/Lotto';
import Navbar from './components/Navbar';
import LottoPage from './components/LottoPage'; // LottoPage 임포트
import PensionPage from './components/PensionPage'; // PensionPage 임포트 (새로 생성 예정)
import SpeedPage from './components/SpeedPage'; // SpeedPage 임포트 (새로 생성 예정)

function App() {
  return (
    <Router>
      <div>
        <Navbar />
        <Routes>
          <Route path="/" element={<Lotto />} />
          <Route path="/community" element={<Community />} />
          <Route path="/lotto" element={<LottoPage />} /> {/* 로또 페이지 라우트 */}
          <Route path="/pension" element={<PensionPage />} /> {/* 연금복권 페이지 라우트 */}
          <Route path="/speed" element={<SpeedPage />} /> {/* 스피또 페이지 라우트 */}
        </Routes>
        <GoogleAd />
      </div>
    </Router>
  );
}

export default App;
