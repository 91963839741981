import React, { useState } from 'react';
import { Link } from 'react-router-dom';

function Navbar() {
  const [hoveredLink, setHoveredLink] = useState(null);

  const handleMouseEnter = (link) => {
    setHoveredLink(link);
  };

  const handleMouseLeave = () => {
    setHoveredLink(null);
  };

  return (
    <nav style={styles.nav}>
      {[
        { path: '/', label: '홈' },
        { path: '/lotto', label: '로또' },
        { path: '/pension', label: '연금복권' },
        { path: '/speed', label: '스피또' }
      ].map((link, index) => (
        <Link
          key={index}
          to={link.path}
          style={hoveredLink === link.path ? { ...styles.link, ...styles.linkHover } : styles.link}
          onMouseEnter={() => handleMouseEnter(link.path)}
          onMouseLeave={handleMouseLeave}
        >
          {link.label}
        </Link>
      ))}
    </nav>
  );
}

const styles = {
  nav: {
    backgroundColor: '#333',  // 어두운 배경
    padding: '15px',
    textAlign: 'center',
  },
  link: {
    color: '#fff',  // 기본 텍스트 색상
    margin: '0 15px',
    textDecoration: 'none',
    fontSize: '18px',
    padding: '10px 15px',
    transition: 'color 0.3s ease',
    fontWeight: 'bold',
  },
  linkHover: {
    color: '#ccc',  // 호버 시 텍스트 색상 변경
  },
};

export default Navbar;
