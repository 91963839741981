// src/components/PensionPage.js
import React, { useState } from 'react';

function PensionPage() {
  const [posts, setPosts] = useState([]);
  const [newPost, setNewPost] = useState('');

  const handlePost = () => {
    const post = {
      id: posts.length + 1,
      content: newPost,
    };
    setPosts([post, ...posts]);
    setNewPost('');
  };

  return (
    <div style={styles.container}>
      <h1>연금복권 게시판</h1>
      <textarea
        value={newPost}
        onChange={(e) => setNewPost(e.target.value)}
        style={styles.textarea}
        placeholder="새 게시글 작성"
      ></textarea>
      <button onClick={handlePost} style={styles.button}>
        게시글 올리기
      </button>
      <div style={styles.postsContainer}>
        {posts.map((post) => (
          <div key={post.id} style={styles.post}>
            <p>{post.content}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

const styles = {
  container: {
    textAlign: 'center',
    padding: '20px',
  },
  textarea: {
    width: '100%',
    height: '100px',
    marginBottom: '10px',
    padding: '10px',
  },
  button: {
    padding: '10px 20px',
    backgroundColor: '#007bff',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  },
  postsContainer: {
    marginTop: '20px',
  },
  post: {
    backgroundColor: '#f8f9fa',
    padding: '15px',
    marginBottom: '10px',
    borderRadius: '5px',
    boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
  },
};

export default PensionPage;
