// src/components/Community.js
import axios from "axios";
import React, { useEffect, useState } from "react";

function Community() {
  const [posts, setPosts] = useState([]);
  const [category, setCategory] = useState("lotto"); // 기본 카테고리

  useEffect(() => {
    async function fetchPosts() {
      try {
        const response = await axios.get(`/api/posts?category=${category}`);
        setPosts(response.data);
      } catch (error) {
        console.error("Error fetching posts:", error);
      }
    }
    fetchPosts();
  }, [category]);

  return (
    <div style={styles.container}>
      <h1>커뮤니티</h1>
      <div style={styles.buttonContainer}>
        <button
          onClick={() => setCategory("lotto")}
          style={category === "lotto" ? styles.activeButton : styles.button}
        >
          로또
        </button>
        <button
          onClick={() => setCategory("pension")}
          style={category === "pension" ? styles.activeButton : styles.button}
        >
          연금복권
        </button>
        <button
          onClick={() => setCategory("speed")}
          style={category === "speed" ? styles.activeButton : styles.button}
        >
          스피또
        </button>
      </div>
      <div style={styles.postContainer}>
        {posts.map((post) => (
          <div key={post.id} style={styles.post}>
            <h2>{post.title}</h2>
            <p>{post.content}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

// 스타일링
const styles = {
  container: {
    textAlign: "center",
    padding: "20px",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    gap: "10px",
    marginBottom: "20px",
  },
  button: {
    padding: "10px 20px",
    fontSize: "16px",
    cursor: "pointer",
    backgroundColor: "#007bff",
    color: "white",
    border: "none",
    borderRadius: "5px",
    transition: "background-color 0.3s ease",
  },
  activeButton: {
    padding: "10px 20px",
    fontSize: "16px",
    cursor: "pointer",
    backgroundColor: "#28a745",
    color: "white",
    border: "none",
    borderRadius: "5px",
    transition: "background-color 0.3s ease",
  },
  postContainer: {
    textAlign: "left",
    maxWidth: "800px",
    margin: "0 auto",
  },
  post: {
    backgroundColor: "#f8f9fa",
    padding: "15px",
    marginBottom: "10px",
    borderRadius: "5px",
    boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)",
  },
};

export default Community;
